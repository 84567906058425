import api from "./api";

const BASE_ENDPOINT = '/jira/projects';

function getAllProject() {
    return api.get(`${BASE_ENDPOINT}`);
}

function getAllPaginated(startAt) {
    return api.get(`${BASE_ENDPOINT}/${startAt}`);
}

function filterPaginated(startAt, name) {
    return api.get(`${BASE_ENDPOINT}/search/${startAt}/${name}`);
}

function filterPaginatedByCategory(startAt, category) {
    return api.get(`${BASE_ENDPOINT}/search/${startAt}/category/${category}`);
}

function getDetailProject(key, initialDate, finalDate) {
    return api.post(`${BASE_ENDPOINT}/${key}/${initialDate}/${finalDate}`,
    {
        key
    });
}

function getTimesProject(key, initialDate, finalDate) {
    return api.get(`${BASE_ENDPOINT}/times/${key}/${initialDate}/${finalDate}`);
}

function salvarMargem(payload) {
    return api.post(
        `${BASE_ENDPOINT}/margem`,
        {...payload}
    );
}

function getAll(email) {
    return api.get(`${BASE_ENDPOINT}/margem/${email}`);
}

function getMargemById(email, id) {
    return api.get(`${BASE_ENDPOINT}/margem/${email}/${id}`);
}

function getMargemforYear(ano, key, email) {
    return api.get(`${BASE_ENDPOINT}/year/${ano}/${key}/${email}`);
}

function updateOneMargem(email, form) {
    return api.put(`${BASE_ENDPOINT}/margem/${email}`, { ...form });
}

function deleteMargem(email, payload) {
    return api.patch(
        `${BASE_ENDPOINT}/margem/${email}/change-active`,
        {...payload}
    );
}

function calculateMargem(form) {
    return api.post(`${BASE_ENDPOINT}/margem/simulator`, { ...form });
}

function getCategories() {
    return api.get(`${BASE_ENDPOINT}/categories`)
}

function saveCustoProjeto(payload) {
    return api.post(`/custo-projeto`, { ...payload });
}

function getCustosProjeto(key, initialDate, finalDate) {
    return api.get(`/custo-projeto/${key}/${initialDate}/${finalDate}`);
}

function updateCustoProjeto(id, payload) {
    return api.put(`/custo-projeto/${id}`, { ...payload });
}

function deleteCustoProjeto(id) {
    return api.delete(`/custo-projeto/${id}`);
}

function findAllByPeriod(tipo, initialDate, finalDate){
    return api.get(`/custo-projeto/projetos/${tipo}/${initialDate}/${finalDate}`);
}

function getCategoryByProject(key){
    return api.get(`/jira/project/category/${key}`);
}

function updateNameMargem(id, data){
    return api.patch(`${BASE_ENDPOINT}/margem/${id}`, data);
}

function getMargemFaturamento(ano, key, email){
    return api.get(`${BASE_ENDPOINT}/faturamento/${ano}/${key}/${email}`)
}

function getMargemCusto(ano, key, email){
    return api.get(`${BASE_ENDPOINT}/custo/${ano}/${key}/${email}`)
}

function getNameMargem(nomeRelatorio){
    return api.get(`${BASE_ENDPOINT}/margem-name/${nomeRelatorio}`)
}

const ProjetosApiService = {
    getAllProject,
    getAllPaginated,
    filterPaginated,
    filterPaginatedByCategory,
    getDetailProject,
    getTimesProject,
    salvarMargem,
    getAll,
    getMargemById,
    getMargemforYear,
    updateOneMargem,
    deleteMargem,
    calculateMargem,
    getCategories,
    saveCustoProjeto,
    getCustosProjeto,
    updateCustoProjeto,
    deleteCustoProjeto,
    findAllByPeriod,
    getCategoryByProject,
    updateNameMargem,
    getMargemFaturamento,
    getMargemCusto,
    getNameMargem
}

export default ProjetosApiService;
